.description ul {
    list-style: none;
    flex-direction: column;
   padding-left: unset; 
    border-bottom: 1px solid var(--main-color);
}

.description ul li {
    padding: 7.5px 0;
    text-transform: uppercase;
    font-size: 20px;
}

.description ul li:first-child {
    font-weight: 700;
    font-family: var(--semibold-font);
}

.description ul li:nth-child(2) {
    font-family: var(--regular-font);
}

.subheading {
    font-family: var(--semibold-font);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: 0.1em;
}

.subheading span {
    padding-right: 1rem;
}

.btn-back {
    padding: 1rem 2rem;
    background: var(--main-color);
    font-family: var(--semibold-font);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    border: none;
}

.case .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-template-rows: repeat(1, 1fr);
}

.case .grid div {
    background-size: cover !important;
    width: 100%;
    height: 500px;
}

.poster {
    display: flex;
    align-items: center;
    justify-content: center;
}

.poster img {
    max-width: 50%;
}