.strength:hover {
  cursor: pointer;
  background: var(--main-color);
  color: black;
}

.strength:hover svg {
  color: black;
}

.content {
  position: absolute;
  display: block;
  height: auto;
  max-width: 200px;
  word-break: break-all;
  text-align: center;
  opacity: 0;
  transition: opacity 0.75s;
}

.partner-box:hover {
  cursor: pointer;
}

.partner-box img {
  transition: opacity 0.75s;
}

.partner-box:hover .content {
  opacity: 1;
  animation: wave 3s ease-in-out infinite;
}

.partner-box:hover img {
  opacity: 0;
}

@keyframes wave {
  0% {
    transform: skewX(0deg);
  }
  20% {
    transform: skewX(-5deg);
  }
  40% {
    transform: skewX(3deg);
  }
  60% {
    transform: skewX(-3deg);
  }
  80% {
    transform: skewX(2deg);
  }
  100% {
    transform: skewX(0deg);
  }
}

@keyframes jumpInfinite {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 10px;
  }
}

.explore-tag::after {
  animation: jumpInfinite 1.5s infinite;
}

.offcanvas.show:not(.hiding), .offcanvas.showing {
  transition: all 1s ease-in-out;
}

button:not(.navbar-toggler, .slick-arrow, 
.btn-close, .carousel-indicators .active, .carousel-indicators [data-bs-target], .btn-content):hover {
  background: var(--main-color);
  color: black;
  border: none;
  font-family: var(--semibold-font);
}

.grid-box img:hover {
  transform: scale(1.2);
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.nav-link:focus, .nav-link:hover {
  color: var(--main-color);
}

.grid-box:hover .btn-content {
  color: white;
  border-bottom: 2px solid white;
}