/* Footer CSS */
.contact-tagline {
  background: black;
  color: white;
  padding: 5rem;
}

.contact-tagline p {
  color: var(--main-color);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: small;
}

.contact-tagline h1 {
    font-size: 5rem;
    text-decoration-line: underline;
}

.footer-section {
  font-family: var(--regular-font);
  overflow: hidden;
}

.footer-section img {
  max-width: 50%;
}

.footer-links {
  padding: 3rem 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  white-space: nowrap;
}

.footer-links a {
  text-decoration: none;
  color: black;
}

.footer-links li {
  list-style-type: none;
  font-size: 30px;
}

.contact-section li {
  list-style-type: none;
  font-size: 27px;
}

.contact-section li:first-child {
  width: 300px;
  line-height: 30px;
}

.contact-section::first-line {
  font-family: var(--main-font);
  font-size: 20px;
  color: var(--grey-color);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.bottom-footer {
  flex-direction: column;
}

.bottom-footer::before, .bottom-footer::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.35);
}

.fbottom {
  display: flex;
  justify-content: flex-end;
}

.terms {
  display: flex;
  align-items: center;
  font-family: Graphik;
  font-size: larger;
}

.terms > span {
  padding: 0rem 1rem;
}

.terms span:first-child {
  border-right: 2px solid rgba(0, 0, 0, 0.11);
  line-height: 2.25em;
}

.social-icons a {
  color: black;
}

.social-icons ul > li {
  border-radius: 50%;
  width: 55px;  height: 55px;
  border: 2px solid #03141B;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  font-size: 22.5px;
}

.dev {
  font-size: medium;
}

.dev a {
  text-decoration: none;
}

.footer-section::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.35);
}