.contact-section .banner, .showcase .banner {
    padding-top: 6rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-control {
    border: 0;
    border-bottom: 1px solid black;
    border-radius: unset;
    padding: 15px 0;
}

.btn-contact {
    width: 100%;
    text-align: center;
    padding: 1rem;
    background: black;
    text-transform: uppercase;
    color: white;
    font-family: var(--regular-font);
}

.contact-form h1 {
    font-size: 80px;
    max-width: 450px;
}

.contact-form ul {
    padding-left: unset;
}
.contact-form li {
    font-size: 18px;
    font-family: var(--regular-font);
}
.contact-form li span {
    font-weight: bold;
}

iframe {
   width: 1080px;
   height: 500px;
}

.form-control:active, .form-control:focus {
    outline: none;
    box-shadow: none;
}

.map {
    padding-left: 2rem;
    margin-bottom: -10px;
}