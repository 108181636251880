.loading {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 5px;
    width: 75%;
    background: grey;
}
.loading .color{
    position: absolute;
    background-color: #FFCB25;
    width: 0px;
    height: 5px;
    animation: progres 5s linear;    
}

@keyframes progres{
    0%{
      width: 0%;
    }
    25%{
        width: 50%;
    }
    50%{
        width: 75%;
    }
    75%{
        width: 85%;
    }
    100%{
        width: 100%;
    }
};