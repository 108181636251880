@media screen and (max-width: 1440px) {
    .carousel-item .col-lg-6:nth-child(even) {
        padding: 5rem !important;
    }
    .carousel-item h1 {
        width: 500px !important;
    }
    .carousel > .carousel-control-next,  .carousel > .carousel-control-prev {
        bottom: 2rem;
        width: unset;
        margin-right: 2.5rem;
    }
    .footer-section img {
        max-width: 80% !important;
    }
    .about-us img {
        height: 100% !important;
        object-fit: cover !important;
    }
    #about {
        padding: 4rem !important;;
    }
    .contact-section, .about-section {
        overflow: hidden;
    }
    .ft-team {
        padding: 0 2rem !important;
    }
    .ft-emp h1 {
        font-size: 60px;
    }
}

@media  screen and (max-width:1200px) {
    .values-section {
        width: 100% !important;
    }
    .portfolio article {
        max-width: 875px !important;
    }
    footer .footer-links {
        /* padding: unset; */
        grid-column-gap: unset; 
    }
    footer .footer-section li {
        font-size: 25px;
    }
    .carousel-item .col-lg-6:nth-child(even) {
        padding: 2rem !important;
    }
    .carousel-item h1 {
        width: 450px !important;
    }
}

@media screen and (max-width: 992px) {
    .tag {
        width: 300px !important; font-size: 1rem !important;
    }
    .tag span {
        font-size: 3rem !important;
    }
    .black-tag .col:nth-child(2) p {
        width: unset !important;
    }
    .banner-text .banner-title { 
        font-size: 6vw;
        max-width: 500px;
    }
    .services h1 {
        font-size: calc(0.75rem + 1.5vw);
    }
    .portfolio article {
        width: 600px !important;
    }
    .poster img {
        max-width: 100%;
    }
    footer .footer-section li {
        font-size: 20px;
    }
    .grid .grid-content {
        padding: 10px 30px;
    }

    @media screen and (min-width: 768px){
        .slider-box {
            text-align: center;
            margin: 0 20px;
        }
        .slider-box img {
            margin: 0 auto;
        }
        .contact-section li:first-child {
            width: 200px;
        }
        .heading {
            font-size: 19px;
        }
        .contact-form h1 {
            font-size: 60px;
        }
    }
    .about-us img {
        max-width: 100% !important;
    }
    @media screen and (min-width: 600px){
        .slider-box {
            margin: 0 15px;
        }
    }
}

@media screen and (max-width: 768px) {
    footer .footer-section li, .contact-section li {
        font-size: 20px;
    }
    .carousel-item h1 {
        width: unset !important;
    }
    .carousel-item p {
        width: unset !important;
    }
    .home .carousel-indicators [data-bs-target] {
        background: grey;
    }
    .portfolio article {
        width: 350px !important;
    }
    .services h1 {
        font-size: calc(0.5rem + 1.5vw);
    }
    .contact-section li {
        font-size: 20px !important;
    }
    #about ul {
        padding-left: 0;
    }
    .about-section, .contact-section {
        overflow: unset !important;
    }
}
    
@media screen and (max-width: 576px){
    .about-us img {
        display: none;
    }
    .black-tag .col:nth-child(2) p {
        font-size: smaller !important;
    }
    .black-tag {
        padding: 5rem 2.5rem !important;
    }
    .banner .banner-title {
        padding-top: 5rem;
    }
    .banner {
        height: 720px !important;
    }
    .tag span {
        font-size: 2.5rem !important;
    }
    .slider-box img, .grid-box img {
        max-width: 300px !important;
    }
    .contact-section li {
        font-size: 17.5px !important;
    }
    footer .footer-links {
        grid-column-gap: 1rem; 
    }
    footer .footer-section img {
        max-width: 90% !important;
    }
    .fbottom, .banner-text {
        flex-direction: column !important;
    }
    .social-icons, .terms {
        margin-top: 2rem;
    }
    .terms {
        font-size: medium;
    }
    .contact-tagline {
        padding: 2rem !important;
    }
    .contact-tagline h1 {
        font-size: 4rem !important;;
    }
    .terms > span {
        padding: 0rem .5rem !important;
    }
    .portfolio {
        padding: 2rem !important;;
    }
    .portfolio article {
        max-width: 250px !important;
    }
    .explore-tag {
        width: unset !important;
        font-size: 1rem !important;
        line-height: 1.25rem !important;
        margin-top: 10rem !important;;
    }
    .banner-text .banner-title {
        font-size: 10vw !important;
    }
    .banner-text .explore-tag::after {
        font-size: 27px;
        right: unset;
    }
    footer .footer-links {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }
    .slider-box {
        text-align: center;
    }
    .slider-box img {
        margin: 0 auto;
    }
    #about {
        padding: 2rem !important;
    }
    .strengths article p {
        font-size: 18px;
    }
    .strengths article h2 {
        font-size: 38px;
    }
    .brands {
        grid-template-columns: repeat(2, 1fr) !important;
    }
    .ft-emp {
        padding: 5rem;
    }
    .ft-emp h5 {
        letter-spacing: 0.25em;
    }
    .ft-emp h1 {
        font-size: 40px;
    }
    .contact-form h1 {
        font-size: 45px;
    }
    .photo-grid .grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .photo-grid .grid div:nth-child(even), 
    .photo-grid .grid div:nth-child(odd) {
        transform: unset !important;
    }
    .navbar-toggler-icon svg {
        font-size: 30px;
    }
    .nav-button {
        padding: 0.5rem 0.75rem;
        font-size: 0.75rem;
        margin-top: 1em;
    }
    .navbar-brand {
        max-width: 150px;
        padding-bottom: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .black-tag {
        flex-direction: column !important;
    }
}