@import './style/footer.css';
@import './style/navbar.css';
@import './style/about.css';
@import './style/contact.css';
@import './style/case.css';
@import './style/transition.css';
@import './style/responsive.css';
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;300;700&display=swap');

:root {
  --main-font: GilroyM;
  --light-font: GilroyT;
  --regular-font: GilroyR;
  --semibold-font: GilroySB;
  --heading-font: 'Barlow Condensed', sans-serif;
  --secondary-color: #c5980a;
  --main-color: #FFCB25;
  --grey-color: #808285;
}

@font-face {
  font-family: GilroyM;
  src: url('./fonts/Gilroy-Medium.ttf');
}

@font-face {
  font-family: GilroyT;
  src: url('./fonts/Gilroy-Thin.ttf');
}

@font-face {
  font-family: GilroyR;
  src: url('./fonts/Gilroy-Regular.ttf');
}

@font-face {
  font-family: GilroySB;
  src: url('./fonts/Gilroy-SemiBold.ttf');
}

@font-face {
  font-family: Graphik;
  src: url('./fonts/GraphikRegular.otf');
}

body {
  font-family: var(--main-font);
  overflow-x: hidden;
}

.banner {
  background: linear-gradient(rgb(0, 0, 0, 0.75), rgb(0, 0, 0, 0.75)), url("./images/mountain.jpg") no-repeat;
  background-size: cover;
  background-position-y: 25%;
  height: 625px;
  color: white;
  flex-direction: column;
}

.banner-text {
  display: flex;
  font-family: var(--main-font);
  justify-content: space-between;
  align-items: center;
}

.banner-title, .portfolio article {
  font-size: 4vw;
  max-width: 975px;
}

.explore-tag {
  word-wrap: break-word;
  text-transform: uppercase;
  width: 25px;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-top: 7.5rem;
}

.explore-tag::after {
  content: "\27F6";
  display: inline-block;
  transform: rotate(90deg);
  font-size: 35px;
  position: relative;
  padding-left: 1.15rem;
  right: 1.25rem;
  color: var(--main-color);
}

.black-tag, .portfolio, .campaigns {
  justify-content: center;
  background: black;
  height: 330px;
  color: white;
  padding: 6rem;
}

.tag {
  word-wrap: break-word;
  width: 450px;
  text-align: left;
  font-size: 1.5rem;
  line-height: 2.25em;
}

.tag span {
  color: var(--main-color);
  font-size: 5rem;
}

.black-tag .col:nth-child(2) p {
  font-family: var(--light-font);
  width: 415px;
  font-size: large;
}

.tagline {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgb(0, 0, 0, 0.75), rgb(0, 0, 0, 0.75)), url("./images/rocket_launch.jpg") no-repeat;
  background-position: 50%;
  background-size: cover;
  font-family: var(--main-font);
  font-size: 4.5vw;
  min-height: 400px;
  color: white;
}

.carousel-item .col-lg-6:nth-child(even) {
  display: flex;
  align-items: center;
  padding: 7.5rem;
}

.carousel-item h1 {
  font-family: var(--semibold-font);
  width: 550px;
}

.carousel-item p {
  color: var(--grey-color); 
  width: 350px;
}

.carousel-control-next, .carousel-control-prev {
  opacity: unset;
  background: var(--main-color);
  top: unset;
  border-radius: 50%;
  padding: 0.5rem;
  bottom: 6rem;
  width: unset;
  margin-right: 7.5rem;
}

.carousel-control-prev {
  left: unset;
  right: 4rem;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background: unset;
  color: black;
  font-size: 1.25rem;
}

.carousel-control-prev-icon:before {
  content: "\2190";
}

.carousel-control-next-icon:before {
  content: "\2192";
}

.portfolio {
  height: unset;
  overflow: hidden;
}

.services {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.services div {
  line-height: 6em;
}

.services h1 {
  color: var(--main-color);
}

.services svg {
  font-size: 45px;
  font-weight: 100;
}

.portfolio article {
  line-height: 1.15em;
  font-size: 3vw;
  font-family: var(--regular-font);
  width: 1000px;
}

.campaigns {
  height: unset;
  padding: unset;
}

.slider-box, .grid-box {
  padding: 0 10px;
  display: flex;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(300px, auto);
  gap: 2rem;
}

.grid a {
  padding: unset;
  text-decoration: none;
  text-align: left;
  color: black;
}

.grid-box {
  padding: unset;
  max-width: 100%;
  height: 100%;
  background-color: var(--main-color);
  position: relative;
  overflow: hidden;
}

.grid-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all .35s ease-out;
}

.hover-overlay {
  transition: all .35s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: brightness(50%);
  opacity: 0;
}

.grid-box:hover .hover-overlay {
  opacity: 1;
}

.grid-content {
  position: relative;
  z-index: 999;
  padding: 15px 30px;
}

.grid-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.grid-box:hover {
  color: white;
  opacity: 0.5;
}

.btn-content {
  background: none;
  border: none;
  text-transform: uppercase;
}

.slider-box img {
  max-width: 350px;
  height: 500px;
  object-fit: cover;
}

.slider-box h3 {
  color: var(--main-color);
}

.slider-box aside, .grid-box aside {
  font-family: var(--light-font);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.grid-box aside {
  font-family: var(--regular-font);
  letter-spacing: 0.05em;
  font-style: italic;
  text-transform: unset;
}

.campaigns h6 {
  color: var(--main-color);
  letter-spacing: 0.2em;
  text-transform: uppercase;
}


.btn-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  visibility: hidden;
  padding: 15px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-top svg {
  color: bl4;
  font-size: 30px;
}

button a {
  color: black;
}

.slider-box a {
  text-decoration: none;
  color: white;
}