.navbar {
    --bs-navbar-toggler-padding-x: 0.75rem 1.5rem;
}

.nav-button {
    text-transform: uppercase;
    color: black;
    background: var(--main-color);
    padding: .5rem 1.75rem;
    font-size: 1.05rem;
    text-align: center;
    letter-spacing: 0.15em;
    border: none;
}

.navbar-toggler {
    border: none;
    outline: none;
}
  
.navbar-toggler-icon {
    background-image: none;
    border: none;
    outline: none;
    height: 1em;
}

.navbar-toggler-icon svg {
    font-size: 45px;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.offcanvas {
    padding: 3.6rem 3rem 3.6rem 3.6rem;
}

.offcanvas-title {
    font-family: var(--semibold-font);
    font-size: 11px;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: .25rem;
    color: var(--main-color);
}

.offcanvas-body ul {
    font-family: var(--semibold-font);
    font-size: 1.8rem;
    list-style: none;
}

.nav-link, .navbar-nav .nav-link .active {
    color: white;
    padding-left: 0;
    line-height: 2.5rem;
}

.navbar-brand {
    max-width: 200px;
}