.about-section .banner, .contact-section .banner, .showcase .banner{
    background: black;
    color: var(--main-color);
    padding-bottom: 6rem;
    height: unset;
}

.about-section .banner-title {
    height: unset;
    padding-top: 6rem;
    margin-top: 5rem;
}

.about-section .black-tag {
    background: unset;
    color: black;
    height: unset;
}

.about-section .tag {
    font-size: 2.5rem;
}

.about-section .black-tag > .col:nth-child(2) p {
    font-family: var(--regular-font);
    font-size: 18px;
}

.about-us img {
    max-width: 105%;
}

#value {
    display: flex;
    background: var(--main-color);
    width: 15rem;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    max-width: fit-content;
    word-wrap: break-word;
    padding: 6.5rem 2rem;
}

.values > .row div p {
    font-size: 18px;
    line-height: 22px;
}

.aboutCarousel article h1 {
    font-family: var(--semibold-font);
}

.aboutCarousel {
    background: rgba(204, 204, 203, 0.3);
    padding: 3.75rem;
}

.aboutCarousel article p {
    width: 500px;
    color: unset;
    font-size: 17.5px;
}

.section {
    padding: 7em 0;
}

.strengths {
    background: url('../images/bg.png') repeat; 
    color: white;
}

.strength, .values-section {
    width: 100%;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    background: #1a1a1a;
    position: relative;
    -webkit-box-shadow: 0px 2px 25px -16px rgb(0 0 0 / 26%);
    -moz-box-shadow: 0px 2px 25px -16px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 2px 25px -16px rgb(0 0 0 / 26%);
}

.heading {
    font-size: 22px;
    max-width: 175px;
}

.strength svg {
    font-size: 45px;
    color: var(--main-color);
}

.strengths article h2 {
    text-transform: uppercase;
    font-size: 45px;
    font-family: var(--semibold-font);
}

.strengths article p {
    color: var(--grey-color);
    font-size: 25px;
    font-family: var(--regular-font);
}

.video-banner {
    background: linear-gradient(rgb(0,0,0, 0.5), rgb(0,0,0,0.5)), url('../images/think-out-box-concepts-with-lightbulb-box.jpg') no-repeat;
    background-size: cover;
    background-position: 50%;
    color: white;
    font-family: var(--semibold-font);
}

.values-section {
    background: black;
    color: white;
    width: 25%;
    padding: 5rem 2.5rem;
    margin-bottom: -15rem;
}

.values h1 {
    color: var(--main-color);
}

.values > .carousel-item p {
    color: white;
    margin-bottom: 6rem;
}

.values-section .carousel-indicators [data-bs-target] {
    width: 20px; height: 20px;
    border-radius: 50%;
}

.ft-emp {
    font-family: var(--heading-font);
}

.ft-emp h5 {
    text-transform: uppercase;
    color: orange;
    letter-spacing: 0.75em;
    font-weight: 300;
}

.ft-emp h1 {
    font-size: 80px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-shadow: 0 8px rgb(0 0 0 / 5%);
}

.grey, .grey2 {
    background: rgba(204, 204, 203, 0.3);
}

.grey {
    margin-top: -50px;
}

.play-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 75px;
    height: 75px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.play-button svg {
    color: var(--main-color);
    font-size: 50px;
}
 
.play-button::after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: #fff;
    border-radius: 50%;
    opacity: .1;
}

.item {
    padding: 0 15px;
}

#about {
    padding-left: 15rem;
}

#about h2 {
    text-transform: uppercase;
    font-family: var(--semibold-font);
}

#about h3 {
    font-family: var(--semibold-font);
    color: var(--secondary-color);
}

#about h3::first-letter {
    text-transform: uppercase;
}

#about ul  {
    list-style: none;
}

#about li h6 {
    font-weight: 600;
    margin-bottom: 5px;
}

#about li span {
    color: var(--main-color);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 13px;
    border: 1px solid var(--main-color);
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
}

#about p {
    padding-left: 2.25rem;
}
.card {
    border: none;
    border-radius: unset;
    text-align: center;
    width: 270px;
}

.ft-team {
    padding: 0 10rem;
}

.slider-arrows {
    display: flex;
    justify-content: space-between;
    width: 125px;
}

.prev-arrow, .next-arrow {
    color: #777;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.partner-box {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partner-box img {
    width: 175px;
}

.brands {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
}

.address {
    background: black;
}

.address .contact-section {
    color: white;
}

.eccentric {
    background: black;
    color: white;
}

.eccentric h1, .case-study h1 {
    max-width: 300px;
}

.eccentric li {
    font-size: 25px;
    font-family: var(--regular-font);
}

.eccentric li::marker {
    color: var(--main-color);
}

.youtube-popwrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    z-index: 99;
}

.youtube-popcontent {
    max-width: 600px;
    display: block;
    margin: 0 auto;
    height: 100%;
    position: relative;
}

.youtube-popcontent iframe {
    max-width:100%;
    width:100%;
    display:block;
    height:480px;
    border:none;
    position:absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.card-img, .card-img-top {
    border-top-right-radius: unset;
    border-top-left-radius: unset;
}